import React from 'react'
import PropTypes from 'prop-types'
import { animateScroll as scroll } from "react-scroll"

const Footer = (props) => {
  const scrollToTop = () => {
    scroll.scrollToTop()
  }

  return (
    <footer className='footer'>
      <div className='container-fluid'>
        <div className='row justify-content-between'>
          <div className='col-md my-2'>
            <span className='text-muted d-block inline-block'>Copyright © 2020 SCT.</span>
          </div>
          <div className='col-md my-2'>
            <div className='d-block inline-block'>
              <p className='pointer text-primary d-inline-block mb-0' onClick={props.onClickPrivacyPolicy}>Privacy Policy</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  onClickPrivacyPolicy: PropTypes.func
}

export default Footer
