import React, { lazy, Suspense, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Router, Route, Switch, Link } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ToastContainer } from 'react-toastify'

import { NotFoundPage, Loading, PrivacyPolicyText } from 'honeybee-ui'
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import { useToggle } from '../hooks'

const HomePage = lazy(() => import('../components/HomePage'))

export const history = createBrowserHistory()

const AppRouter = (props) => {
  const [isOpen, toggleModal] = useToggle()

  return (
    <Fragment>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover />
      <Router history={history}>
        <div className='container-scroller'>
          <NavBar />
          <Suspense fallback={<Loading main={true} />}>
            <Switch>
              <Route path='/' component={HomePage} exact />

              <Route component={() => <NotFoundPage><Link className='text-white font-weight-medium' to='/'>Take me back</Link></NotFoundPage>} />
            </Switch>
          </Suspense>

          <Footer
            onClickPrivacyPolicy={toggleModal} />

          <Modal
            isOpen={isOpen}
            toggle={toggleModal}
            size='lg'
            style={{ width: '92vw', margin: '80px auto' }}>
            <ModalBody>
              <h1 className='display-2 mb-2'>Privacy Policy</h1>
              <hr className='mb-4' />
              <PrivacyPolicyText />
            </ModalBody>
            <ModalFooter>
              <button className='btn btn-link btn-lg btn-block' onClick={toggleModal}>Cancel</button>
            </ModalFooter>
          </Modal>
        </div>
      </Router>
    </Fragment>

  )
}

AppRouter.propTypes = {
  accountID: PropTypes.number,
  isLoggedIn: PropTypes.bool,
  startLogout: PropTypes.func
}

export default AppRouter
